import type { ArticleImageProps } from '@/app/components/ArticleCardsModule';
import type { ArticleCardDataProps } from '@/app/components/ArticleCardsModule/ArticleCard';

export const ARTICLE_CARDS: ArticleCardDataProps[] = [
  {
    heading: 'homepage_press_article_1',
    buttonProps: {
      ariaLabel: 'press_read_article',
      external: true,
      color: 'dark',
      href: 'https://www.wired.com/review/oura-ring-4/',
      rel: 'noopener noreferrer',
      siteName: 'Wired',
      target: '_blank',
    },
  },
  {
    heading: 'homepage_press_article_2_chloe_aiello',
    buttonProps: {
      ariaLabel: 'press_read_article',
      external: true,
      color: 'dark',
      href: 'https://www.inc.com/chloe-aiello/oura-just-landed-200-million-to-develop-new-ai-powered-wearables/91068538',
      rel: 'noopener noreferrer',
      siteName: 'Inc',
      target: '_blank',
    },
  },
  {
    heading: 'homepage_press_article_3_axios',
    buttonProps: {
      ariaLabel: 'press_read_article',
      external: true,
      color: 'dark',
      href: 'https://www.axios.com/2025/02/04/oura-ai-local-device-data',
      rel: 'noopener noreferrer',
      siteName: 'Axios',
      target: '_blank',
    },
  },
];

export const ARTICLE_IMAGE: ArticleImageProps = {
  alt: 'press_section_primary_image_alt_ai',
  buttonProps: {
    external: true,
    href: 'https://ouraring.com/blog/the-future-of-wellness/',
    label: 'read_more',
    rel: 'noopener noreferrer',
    siteName: 'Oura Ring Blog',
    target: '_blank',
    variant: 'secondary-light',
  },
  heading: 'homepage_press_hero_article_ai',
  mobileSrc:
    'homepage/press-hero-ai.jpg?fp-y=0.60&fp-x=0.45&fp-z=1.1&fit=crop&crop=focalpoint',
  src: 'homepage/press-hero-ai.jpg?fp-x=0.40&fp-z=1.25&fit=crop&crop=focalpoint',
};
