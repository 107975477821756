import { useExperiment } from '@statsig/react-bindings';
import { useTranslations } from 'next-intl';
import { type MessageKey } from 'types/LocaleMessageKey';

import { EventType } from '@/analytics/types';
import { type ImageFormat } from '@/app/components/Image';
import SectionHeading from '@/app/components/SectionHeading';

import HomeHero from '../HomeHero';
import { HomeHeroShopChiclet } from '../HomeHeroShopChiclet';
import HomeHeroWithChicletWrapper from '../HomeHeroWithChicletWrapper';
import DexcomHeroBackground from './common/DexcomHeroBackground';
import HsaFsaIcon from './common/HsaFsaIcon';

const defaultValues = {
  title: 'dexcom-hero-title' as MessageKey,
  copy: 'dexcom-hero-copy' as MessageKey,
  cta: 'frontpage_learn_more' as MessageKey,
  href: '/metabolic-health',
  hasHsaIcon: false,
  additionalSegmentProperties: {
    cta: 'frontpage_learn_more',
    action: 'go_to_metabolic',
    location: 'home hero',
    path: '/metabolic-health',
  },
  chiclet: {
    additionalSegmentProperties: {
      cta: 'shop_stelo' as MessageKey,
      location: 'chiclet',
      path: '/stelo-glucose-biosensor',
      action: 'shop_stelo',
    },
    ctaLocaleKey: 'shop_stelo' as MessageKey,
    image: {
      altKey: 'gift_cards_chiclet_img_alt' as MessageKey,
      src: 'Rectangle_33207_SMHURJ',
      format: 'png',
    },
    localeKey: 'dexcom_hero_chiclet_copy' as MessageKey,
    target: '',
    rel: '',
    url: '/stelo-glucose-biosensor',
  },
};

const DexcomHero = (): JSX.Element => {
  const t = useTranslations();
  const experiment = useExperiment('dexcom_hero_test');
  const {
    title,
    copy,
    cta,
    href,
    additionalSegmentProperties,
    chiclet,
    hasHsaIcon,
  } = experiment.get('values', defaultValues) as typeof defaultValues;

  return (
    <HomeHeroWithChicletWrapper data-cy="dexcom-launch-hero">
      <HomeHero
        heroContent={{
          button: {
            children: null,
            variant: 'secondary-light',
            href,
            label: cta,
            className: 'w-full mb-5 md:mb-0 md:w-auto ',
            onClick: () => {
              void window.ouraAnalytics.track(
                EventType.CTAClicked,
                additionalSegmentProperties,
              );
            },
          },
          copy: {
            value: t.rich(copy),
            className: 'text-base',
          },
          icon: hasHsaIcon ? <HsaFsaIcon /> : undefined,
        }}
        overlayPosition="absolute"
        titleSlotOptions={{
          className: '!content-end md:!content-between', // Allows us to push down the content to the bottom on mobile
        }}
        TitleSlot={
          <div className="mb-5 mt-24 md:mt-[27.5vh] lg:mt-[25vh]">
            <SectionHeading
              content={title}
              emProps={{
                className: 'block pt-2 md:pt-10',
              }}
              typographyProps={{
                className:
                  'leading-tighter text-center text-4xl md:text-6xl md:text-left',
                color: 'light',
                Element: 'h1',
                variant: 'h1',
              }}
            />
          </div>
        }
        TopSlot={<DexcomHeroBackground />}
      />
      <HomeHeroShopChiclet
        className="!mb-5 md:mb-0"
        title={chiclet.localeKey}
        cta={{
          href: chiclet.url,
          label: chiclet.ctaLocaleKey,
          rel: chiclet.rel,
          target: chiclet.target,
        }}
        image={{
          format: chiclet.image.format as ImageFormat,
          localizedAlt: chiclet.image.altKey,
          src: chiclet.image.src,
        }}
        segmentProperties={chiclet.additionalSegmentProperties}
      />
    </HomeHeroWithChicletWrapper>
  );
};

export default DexcomHero;
