import { useReducedMotion } from 'framer-motion';
import { memo } from 'react';

import Image from '@/app/components/Image';
import ArtDirectionImage from '@/app/components/Image/ArtDirectionImage';
import Motion from '@/app/components/Motion';
import Video from '@/app/components/Video/Video';

const StaticHero = () => {
  return (
    <Motion
      className="relative z-10 h-[calc(100svh-50px)] md:h-full"
      animate={{ opacity: 1, scale: 1 }}
      initial={{ opacity: 0, scale: 1.1 }}
      transition={{ duration: 1.5, type: 'tween' }}
    >
      <Image
        className="object-cover"
        fill
        format="png"
        localizedAlt="or4_home_page_hero_alt"
        priority
        sizes="100vw"
        src="Oura_Marketing_Asset_still_COMP_v005.001_FINAL_3_T0Z3L0.png"
      />
      <ArtDirectionImage
        className="object-cover"
        fill
        localizedAlt="or4_home_page_hero_alt"
        priority
        sizes="100vw"
        srcSet={[
          {
            media: '(max-width: 767px)',
            src: 'Group_2055247910_C7JTUK.png',
          },
          {
            media: '(min-width: 768px)',
            src: 'Oura_Marketing_Asset_still_COMP_v005.001_FINAL_3_T0Z3L0.png',
          },
        ]}
      />
    </Motion>
  );
};

const VideoHero = memo(function VideoHero() {
  return (
    <div className="h-full bg-black">
      <Video
        className="relative hidden h-screen min-h-[50em] w-full object-cover md:block"
        autoPlay
        contentTitle="pop_hero"
        location="pop_hero"
        type="video/mp4"
        muted
        posterSrc="Oura_Marketing_Asset_still_COMP_v005.001_FINAL_3_T0Z3L0.png"
        playVideo
        preload="auto"
        priority
        controls={false}
        playsInline
        loop
        src="dexcom-hero-desktop-v2.mp4"
      />
      <Video
        className="relative size-full object-cover md:hidden"
        style={{
          maskImage: `
            linear-gradient(to top,
              rgba(0, 0, 0, 0.1) 35%,
              rgba(0, 0, 0, 0.5) 45%,
              rgba(0, 0, 0, 1) 100%
            )
          `,
        }}
        autoPlay
        contentTitle="pop_hero"
        location="pop_hero"
        type="video/mp4"
        muted
        loop
        posterSrc="Group_2055247910_C7JTUK.png"
        playVideo
        preload="auto"
        priority
        controls={false}
        playsInline
        src="dexcom-hero-mobile-v2.mp4"
      />
    </div>
  );
});

const OR4HeroBackground = (): JSX.Element => {
  const shouldReduceMotion = useReducedMotion();

  if (!shouldReduceMotion) {
    return <VideoHero />;
  } else {
    return <StaticHero />;
  }
};

export default OR4HeroBackground;
