import {
  ActivityIcon,
  HeartIcon,
  HeartRangeMetricIcon,
  MeditationIcon,
  MoonIcon,
  WellnessIcon,
} from '@jouzen/ecom-components';
import type { SVGIconComponent } from 'types/svgr';

import type { Slide } from '../types';

export const sliderContentOR4 = (): Slide[] => [
  {
    app: {
      alt: 'sleep_app_image_alt',
      src: 'sleep_rest_app_ui',
    },
    button: {
      label: 'learn_more',
      href: '/sleep-and-rest',
    },
    description: 'homepage_sleep_hsa_compliance_copy',
    id: 0,
    image: {
      desktop: {
        alt: 'woman_sleeping_image_alt',
        src: 'why-oura/oreo/slide-1-sleep',
      },
      mobile: {
        default: {
          alt: 'woman_sleeping_image_alt',
          src: 'home/OR4/woman-sleeping-mobile',
        },
        overlay: {
          alt: 'woman_sleeping_image_alt',
          src: 'home/OR4/woman-sleeping-mobile-overlay',
        },
      },
    },
    tag: {
      label: 'membership_sleep_card_tag',
      icon: MoonIcon as SVGIconComponent,
    },
    title: 'membership_sleep_card_title',
    quote: {
      author: 'Lisa D.',
      content: 'membership_sleep_card_quote',
      image: {
        src: 'blue-sky/home/lisa-d-thumb',
        alt: 'membership_sleep_card_author_image_alt',
      },
    },
  },
  {
    app: {
      alt: 'readiness_app_image_alt',
      src: 'wellness_app_ui',
    },
    button: {
      label: 'learn_more',
      href: `https://ouraring.com/blog/category/health/`,
      target: '_blank',
    },
    description: 'membership_wellness_card_copy',
    id: 1,
    image: {
      desktop: {
        alt: 'heart_health_family_alt',
        src: 'why-oura/oreo/slide-4-wellness',
      },
      mobile: {
        default: {
          src: 'home/OR4/3-gen-mobile',
          alt: 'heart_health_family_alt',
        },
        overlay: {
          src: 'home/OR4/3-gen-mobile-overlay',
          alt: 'heart_health_family_alt',
        },
      },
    },
    tag: {
      label: 'wellness_tag',
      icon: WellnessIcon as SVGIconComponent,
    },
    title: 'membership_wellness_card_title',
    quote: {
      author: 'Lindsay H.',
      content: 'membership_wellness_card_quote',
      image: {
        src: 'blue-sky/home/lindsay-h-thumb',
        alt: 'membership_wellness_card_author_image_alt',
      },
    },
  },
  {
    app: {
      alt: 'activity_app_image_alt',
      src: 'activity_app_ui',
    },
    button: {
      label: 'learn_more',
      href: '/activity-and-movement',
    },
    description: 'membership_activity_card_copy',
    id: 2,
    image: {
      desktop: {
        alt: 'man_exercise_alt',
        src: 'why-oura/oreo/slide-3-activity',
      },
      mobile: {
        default: {
          src: 'home/OR4/activity-mobile',
          alt: 'man_exercise_alt',
        },
        overlay: {
          src: 'home/OR4/activity-mobile-overlay',
          alt: 'man_exercise_alt',
        },
      },
    },
    tag: {
      label: 'membership_activity_card_tag',
      icon: ActivityIcon as SVGIconComponent,
    },
    title: 'membership_activity_card_title',
    quote: {
      author: 'Marcus B.',
      content: 'membership_activity_card_quote',
      image: {
        src: 'blue-sky/home/marcus-b-thumb',
        alt: 'membership_activity_card_author_image_alt',
      },
    },
  },
  {
    app: {
      alt: 'heart_rate_app_image_alt',
      src: 'heart_rate_app_ui',
    },
    button: {
      label: 'learn_more',
      href: '/heart-health',
    },
    description: 'membership_heart_card_copy',
    id: 3,
    image: {
      desktop: {
        alt: 'man_lying_down_alt',
        src: 'home/OR4/home-page-heart',
      },
      mobile: {
        default: {
          src: 'home/OR4/man-hand-heart-mobile',
          alt: 'man_lying_down_alt',
        },
        overlay: {
          src: 'home/OR4/man-hand-heart-mobile-overlay',
          alt: 'man_lying_down_alt',
        },
      },
    },
    tag: {
      label: 'membership_heart_card_tag',
      icon: HeartIcon as SVGIconComponent,
    },
    title: 'membership_heart_card_title',
    quote: {
      author: 'Dave K.',
      content: 'membership_heart_card_quote',
      image: {
        src: 'blue-sky/home/dave-k-thumb',
        alt: 'membership_heart_card_author_image_alt',
      },
    },
  },
  {
    app: {
      alt: 'cycle_insights_app_image_alt',
      src: 'cycle_tracking_app_ui',
    },
    button: {
      label: 'learn_more',
      href: '/womens-health',
    },
    description: 'membership_women_health_card_copy',
    id: 4,
    image: {
      desktop: {
        alt: 'science_and_research_card_three_alt',
        src: 'why-oura/oreo/slide-5-womens',
      },
      mobile: {
        default: {
          alt: 'science_and_research_card_three_alt',
          src: 'home/OR4/women-preg-mobile',
        },
        overlay: {
          alt: 'science_and_research_card_three_alt',
          src: 'home/OR4/women-preg-mobile-overlay',
        },
      },
    },
    tag: {
      label: 'membership_women_health_card_tag',
      icon: HeartRangeMetricIcon as SVGIconComponent,
    },
    title: 'membership_women_health_card_title',
    quote: {
      author: 'Cassidy N.',
      content: 'membership_women_health_card_quote',
      image: {
        src: 'blue-sky/home/cassidy-n-thumb',
        alt: 'membership_women_health_card_author_image_alt',
      },
    },
  },
  {
    app: {
      alt: 'daytime_stress_app_image_alt',
      src: 'stress_app_ui',
    },
    button: {
      label: 'learn_more',
      href: '/stress',
    },
    description: 'membership_stress_card_copy',
    id: 5,
    image: {
      desktop: {
        alt: 'woman_park_alt',
        src: 'why-oura/oreo/slide-6-stress',
      },
      mobile: {
        default: {
          src: 'home/OR4/woman-stress-mobile',
          alt: 'woman_park_alt',
        },
        overlay: {
          src: 'home/OR4/woman-stress-mobile-overlay',
          alt: 'woman_park_alt',
        },
      },
    },
    tag: {
      label: 'membership_stress_card_tag',
      icon: MeditationIcon as SVGIconComponent,
    },
    title: 'membership_stress_card_title',
    quote: {
      author: 'Udo S.',
      content: 'membership_stress_card_quote',
      image: {
        src: 'blue-sky/home/udo-s-thumb',
        alt: 'membership_stress_card_author_image_alt',
      },
    },
  },
];
