export interface RingCardData {
  dataCyExplore: string;
  dataCyShop: string;
  hrefExplore: string;
  hrefShop: string;
  id: 'gen3' | 'or4';
  location: string;
  mobileSrc: string;
  pdpAction: string;
  popAction: string;
  src: string;
  title: string;
}

const ringCardData: RingCardData[] = [
  {
    dataCyExplore: 'OR4Explore',
    dataCyShop: 'OR4Shop',
    hrefExplore: '/product/rings/',
    hrefShop: '/product/rings/oura-ring-4/silver/',
    id: 'or4',
    location: 'homepage or4 module',
    mobileSrc: 'blue-sky/home/OR4-ring-module-v2.jpg',
    pdpAction: 'go to or4 wizard',
    popAction: 'go to or4 pop',
    src: 'blue-sky/home/OR4-ring module-v2-desktop.jpg',
    title: 'pdp_style_oura_ring_4',
  },
  {
    dataCyExplore: 'OR3Explore',
    dataCyShop: 'OR3Shop',
    hrefExplore: '/product/rings/oura-gen3/',
    hrefShop: '/product/rings/oura-gen3/heritage/',
    id: 'gen3',
    location: 'homepage gen3 module',
    mobileSrc: 'home/bfcm/OR3-ring-module.jpg',
    pdpAction: 'go to gen3 wizard',
    popAction: 'go to gen3 pop',
    src: 'home/OR3-bfcm.jpg',
    title: 'hero_title_variant_one',
  },
];

export default ringCardData;
