import { useTranslations } from 'next-intl';
import type { ReactEventHandler, VideoHTMLAttributes } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { preload } from 'react-dom';

import Image from '@/app/components/Image';
import { getAssetUrl } from '@/utils/getAssetUrl';

import useVideoSources from './hooks/useVideoSources';
import imgixLoader from './loaders/imgixLoader';
import type { VideoProps } from './types/VideoProps';

const Video = ({
  altImage,
  autoPlay = false,
  captions,
  className,
  controls = true,
  currentTime,
  loader = (videoSrc) => imgixLoader(videoSrc),
  localizedSrc,
  localizedSrcSet,
  loop = false,
  muted = false,
  playsInline = false,
  playVideo,
  posterSrc,
  priority = false,
  src = '',
  srcSet,
  type,
  style,
}: VideoProps): JSX.Element => {
  const videoElRef = useRef<HTMLVideoElement | null>(null);
  const [canPlayVideo, setCanPlayVideo] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const t = useTranslations();
  const poster = posterSrc ? imgixLoader(posterSrc) : undefined;

  const videoAttrs = useMemo(
    (): Partial<VideoHTMLAttributes<HTMLVideoElement>> => ({
      loop,
      playsInline,
      autoPlay,
      muted,
      controls,
      poster,
    }),
    [loop, playsInline, autoPlay, muted, controls, poster],
  );

  const videoSources = useVideoSources({
    loader,
    localizedSrc,
    localizedSrcSet,
    src,
    srcSet,
    type,
  });

  const videoRef = useCallback(
    (video: HTMLVideoElement | null) => {
      if (video) {
        videoElRef.current = video;
        // Set state for canPlayVideo based on video's initial readyState
        setCanPlayVideo(video.readyState >= video.HAVE_FUTURE_DATA);
        /**
         * React ignores muted attribute:
         * https://github.com/facebook/react/issues/10389
         */
        if (muted) {
          video.setAttribute('muted', '');
        } else {
          if (video.hasAttribute('muted')) {
            video.removeAttribute('muted');
          }
        }
      }
    },
    [muted],
  );
  const altImageAlt = t('video_alt_image_alt_default');

  // Preload video source if priority is set to true
  useEffect(() => {
    if (priority && videoAttrs.src) {
      preload(videoAttrs.src, { as: 'video', fetchPriority: 'high' });
    }
  }, [videoAttrs, priority]);

  // Trigger play video playVideo prop change
  useEffect(() => {
    if (canPlayVideo && typeof playVideo !== 'undefined') {
      if (playVideo) {
        void videoElRef.current?.play();
      } else {
        void videoElRef.current?.pause();
      }
    }
  }, [canPlayVideo, playVideo]);

  useEffect(() => {
    if (
      canPlayVideo &&
      videoElRef.current &&
      typeof currentTime == 'number' &&
      videoElRef.current.currentTime !== currentTime
    ) {
      videoElRef.current.currentTime = currentTime;
    }
  }, [canPlayVideo, currentTime]);

  const handleCanPlayThrough: ReactEventHandler<HTMLVideoElement> = () => {
    if (!canPlayVideo) {
      setCanPlayVideo(true);
    }
  };

  const handleLoadedMetadata: ReactEventHandler<HTMLVideoElement> = (event) => {
    if (duration === 0) {
      setDuration(event.currentTarget.duration);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      {...videoAttrs}
      className={className}
      data-cy="video"
      onCanPlayThrough={handleCanPlayThrough}
      onLoadedMetadata={handleLoadedMetadata}
      ref={videoRef}
      style={{ maskImage: 'none', backfaceVisibility: 'hidden', ...style }}
    >
      {videoSources.map(({ ...source }, idx) => (
        <source {...source} key={idx} />
      ))}

      {captions?.map((caption, index) => (
        <track
          data-cy="video_track"
          key={index}
          label={caption.label}
          kind="subtitles"
          srcLang={caption.lang}
          src={getAssetUrl(caption.src)}
        />
      ))}

      {altImage?.src && (
        <div className="flex h-full items-center justify-center">
          <Image
            data-cy="video_alt_image"
            draggable="false"
            style={{ objectFit: 'cover' }}
            {...altImage}
            alt={altImage.alt ?? altImageAlt}
          />
        </div>
      )}
    </video>
  );
};

export default Video;
