import { usePathname } from 'next/navigation';

import { EventType } from '@/analytics/types';
import LandingPageHero from '@/app/components/LandingPage/LandingPageHero';

import HomeHeroShopChiclet from '../HomeHeroShopChiclet';
import HomeHeroWithChicletWrapper from '../HomeHeroWithChicletWrapper';
import HsaFsaIcon from './common/HsaFsaIcon';

const Gen3Holiday2025Hero = (): JSX.Element => {
  const path = usePathname();

  const handleCtaClick = () => {
    void window.ouraAnalytics.track(EventType.CTAClicked, {
      cta: 'shop_now',
      action: 'go_to_pdp',
      location: 'hero_module',
      module: 'hero_module',
      path,
    });
  };

  return (
    <HomeHeroWithChicletWrapper>
      <LandingPageHero
        backgroundImage={{
          alt: '',
          srcSet: [
            {
              media: '(max-width: 1023px)',
              src: 'holiday23/gifting-hero-mobile.jpg?bri=-10',
            },
            {
              media: '(min-width: 1024px)',
              src: 'holiday23/gifting-hero-desktop.jpg?bri=-10',
            },
          ],
        }}
        backgroundProps={{
          color: 'sandstone-200',
          gradients: [],
        }}
        bottomIcon={<HsaFsaIcon />}
        copy="gen3_holiday_hero_copy_2025"
        cta={{
          href: '/product/rings/oura-gen3',
          label: 'bfcm_2024_home_hero_cta',
          onClick: handleCtaClick,
        }}
        data-cy="gen3-holiday-2025-hero"
        title="holiday_2024_hero_title"
        titleProps={{
          indentation: 'pl-[10rem] md:pl-[15rem] lg:pl-[20rem]',
          typographyProps: {
            className: 'en:lg:whitespace-nowrap',
          },
        }}
        textColor="text-sandstone-200"
      />
      <HomeHeroShopChiclet wrap="lg" />
    </HomeHeroWithChicletWrapper>
  );
};

export default Gen3Holiday2025Hero;
