import { HsaFsaInlineLogo, TypographyV3 } from '@jouzen/ecom-components';
import { useFeatureGate } from '@statsig/react-bindings';
import { useTranslations } from 'next-intl';

const HsaFsaIcon = (): JSX.Element => {
  const t = useTranslations();
  const isUS = useFeatureGate('usa_only').value;

  if (isUS) {
    return (
      <div
        className="flex flex-row items-center justify-center"
        data-cy="hsa-fsa-icon"
      >
        <TypographyV3 className="text-white" weight="bold">
          {t.rich('hsa_fsa_eligible_line_item', {
            logo: () => (
              <HsaFsaInlineLogo
                className="inline-block -translate-y-px"
                color="white"
              />
            ),
          })}
        </TypographyV3>
      </div>
    );
  } else {
    return <></>;
  }
};

export default HsaFsaIcon;
