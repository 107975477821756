//TODO: GROW-2166 when Productizing if fixed win update the InverseHeader in core component and depricate this component
import type { InverseHeaderProps } from '@jouzen/ecom-components';
import { InverseHeader as InverseHeaderCore } from '@jouzen/ecom-components';

import useFixedHeader from '@/app/experiments/FixedHeader/useFixedHeader';

const InverseHeader = ({ children = null, ...props }: InverseHeaderProps) => {
  const isFixed = useFixedHeader();

  if (isFixed) {
    return (
      <div className={props.className} data-cy="default-no-inverse">
        {children}
      </div>
    );
  } else {
    return <InverseHeaderCore {...props}>{children}</InverseHeaderCore>;
  }
};
export default InverseHeader;
